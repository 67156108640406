<template>
  <div>
    <div class="container">
      <el-row :gutter="20">
        <el-col :span="12">
            <div class="topBox flex">
                <div class="leftBox">
                    <h2>{{patientData.status}}</h2>
                    <div class="number">预约单号：{{patientData.code}}</div>
                </div>
                <div class="rightBox">创建时间：{{patientData.create_time}}</div>
            </div>
           
            <div class="mgb20">
                <h4 class="title">预约信息</h4>
                <div class="bar">专家名称：{{patientData.name}}</div>
                <div class="bar">咨询项目：{{patientData.project}}</div>
                <div class="bar">就诊时间：{{patientData.visiting_time}}</div>
                <div class="bar">备注：{{patientData.msg}}</div>
                <div class="flex_align" v-if="patientData.status === '待咨询'">
                    <el-button v-if="patientData.statuss == 0" type="primary" class="btn" @click="receive(patientData.id)">接 诊</el-button>
                    <el-button type="success" class="btn" @click="onReferral">转 诊</el-button>
                </div>
                
            </div>
            
        </el-col>
        <el-col :span="12">
            <el-row :gutter="20" class="mgb20 rightWrap">
                <h4 class="title">基础信息</h4>
                <div class="bar">就诊人：{{patientData.names}}</div>
                <div class="bar">性别：{{patientData.sex}}</div>
                <div class="bar">年龄：{{patientData.age}}</div>
                <div class="bar">出生日期：{{patientData.birthday}}</div>
                <div class="bar">联系电话：{{patientData.mobile}}</div>
                <div class="bar">居住地址：{{patientData.address}}</div>
                <div class="flex_align"><el-button type="primary" class="btn" @click="saveEdit(patientData.info_id)">查看健康档案</el-button></div>
            </el-row>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="转诊" v-model="dialogFormVisible">
        <el-form :model="form">
            <el-form-item label="选择专家：" label-width="120px">
            <el-select v-model="medic_id" placeholder="请选择" @change="changeMedic">
                <el-option v-for="(item,index) in medicList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="选择日期：" label-width="120px">
            <el-select v-model="queryDate" placeholder="请选择" @change="changeDate">
                <el-option v-for="(item,index) in dateList" :key="index" :label="item.dates" :value="item.date"></el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="选择时间段：" label-width="120px" @change="changeTime">
            <el-select v-model="working_id" placeholder="请选择">
                <el-option v-for="(item,index) in timeList" :key="index" :label="item.times" :value="item.id"></el-option>
            </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="subimtReferral">确 定</el-button>
            </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>
import { registrationInfo,getMedic,getMedicDate,getMedicTime,referralFn } from "../../api/index";
export default {
  name: "registerInfo",
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      patientData:{},
      medicList: [],    // 医生列表
      medic_id: '',
      dateList: [],     // 日期
      queryDate:'',
      timeList: [],     // 时间段
      working_id: ''
    };
  },
    created() {
        let id = this.$route.query.userid
        registrationInfo({id}).then((res)=>{
            this.patientData = res
        })
    },
    methods: {
        onGetMedic(){
            getMedic().then((res)=>{
                console.log(res)
                this.medicList = res
            })
        },
        onGetMedicDate(){
            let medic_id = this.medic_id
            getMedicDate({medic_id}).then((res)=>{
                console.log(res)
                this.dateList = res
            })
        },
        onGetMedicTime(){
            let medic_id = this.medic_id
            let date = this.queryDate
            getMedicTime({medic_id,date}).then((res)=>{
                console.log(res)
                this.timeList = res
            })
        },
        // 转诊
        subimtReferral(){
            let working_id = this.working_id
            let id = this.patientData.id
            if(!working_id){
                this.$message.error("请选择医生和时间段");
                return
            }
            referralFn({working_id, id}).then(()=>{
                this.$message.success("转诊成功");
                this.dialogFormVisible = false
                registrationInfo({id}).then((res)=>{
                    this.patientData = res
                })
            })
        },
        onReferral(){
            this.dialogFormVisible = true
            this.onGetMedic()
        },
        // 切换医生/日期
        changeMedic(){ 
            this.onGetMedicDate()
            this.queryDate = ''
            this.working_id = ''
        },
        changeDate(){
            this.onGetMedicTime()
            this.working_id = ''
        },

        receive(id){
            this.$router.push({path: '/registerCase',query:{userid:id, types:1}})
        },
        saveEdit(id){
            this.$router.push({path: '/memberInfo',query:{id:id}})
        },
    },

};
</script>

<style scoped>
.topBox{
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.leftBox{
    display: flex;
    align-items: flex-end;
}
.number{
    margin-left: 50px;
    font-size: 14px;
    color: #999;
}
.rightBox{
    font-size: 14px;
    color: #999;
}
.mgb20{
    display: flex;
    flex-direction: column;
}
.bar{
    font-size: 14px;
    line-height: 40px;
}
.flex_align{
    padding-top: 20px;
}
.btn{
   width: 150px;
   height: 36px; 
}
.rightWrap{
    padding-left: 50px;
    box-sizing: border-box;
}
.title{
    margin-bottom: 20px;
}
.input{
    width: 218px;
}
</style>

